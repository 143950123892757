<template>
  <div class="h-scroller-container">
    <div
      class="control--left"
      @click="hscroll('left')"
      v-if="showControl"
    ></div>
    <div
      class="control--right"
      @click="hscroll('right')"
      v-if="showControl"
    ></div>
    <slot />
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "horizontalScroller",
  data() {
    return {
      showControl: false,
    };
  },
  methods: {
    hscroll(direction) {
      let scrollableElem = this.$el.lastElementChild;
      let scrollableElemPosition = scrollableElem.scrollLeft;
      let scrollLength =
        scrollableElem.scrollWidth / scrollableElem.childElementCount;

      if (direction == "left") {
        $(scrollableElem).animate({
          scrollLeft: scrollableElemPosition - scrollLength,
        });
      } else if (direction == "right") {
        $(scrollableElem).animate({
          scrollLeft: scrollableElemPosition + scrollLength,
        });
      }
    },
    toggleVisibility() {
      if (this.$el.clientWidth < this.$el.lastElementChild.scrollWidth) {
        this.showControl = true;
      } else {
        this.showControl = false;
      }
    },
  },
  mounted() {
    $(window).resize(() => {
      this.toggleVisibility();
    });
  },
};
</script>

<style lang="scss" scoped>
.h-scroller-container {
  width: 100%;
  position: relative;
  overflow: hidden;

  div[class^="control--"] {
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000009;
    padding: 0px 15px;
    height: 100%;
    opacity: 0.5;
    z-index: 1000;
    transition: opacity 0.3s;

    &::before {
      font-size: 3rem;
      font-weight: 700;
      color: #444;
    }

    &.control--left {
      left: 0px;

      &::before {
        content: "‹";
      }
    }

    &.control--right {
      right: 0px;

      &::before {
        content: "›";
      }
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}
</style>