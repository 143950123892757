<template>
  <div id="component-tower-details-overview" class="content-with-sidebar">
    <div class="sidebar">
      <div class="sidebar-card">
        <h3 class="title">
          <i class="far fa-file-alt mr-2"></i>
          Tower Data
        </h3>
        <div class="p-4">
          <table class="table table-borderless table-sm mb-0">
            <tr>
              <td
                class="text-muted text-right font-italic font-weight-light"
                style="font-size: 12px"
              >
                Coordinate
              </td>
              <td class="pl-4">{{ tower.lat }}, {{ tower.lng }}</td>
            </tr>
            <tr v-for="kv in towerData" :key="kv.k">
              <td
                class="text-muted text-right font-italic font-weight-light"
                style="font-size: 12px"
              >
                {{ kv.k }}
              </td>
              <td class="pl-4">
                {{ kv.v }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="sidebar-card" v-if="tower.company">
        <h3 class="title">
          <i class="fas fa-users mr-2"></i>
          Tower Owner
        </h3>
        <div class="media p-4">
          <img
            :src="tower.company.image.url"
            class="mr-3"
            alt="..."
            style="width: 80px"
          />
          <div class="media-body">
            <h6 class="mt-0" style="font-size: 16px">
              {{ tower.company.name }}
            </h6>
            <span class="text-muted" style="font-size: 12px">
              {{ tower.company.address }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-header row mx-0 overview-summary">
        <div class="col-6 col-lg-3" @click="goToSection('assets')">
          <h3 class="mb-0">{{ totalAntennas }}</h3>
          <p>
            Total Antennas
            <i class="fas fa-angle-right ml-2"></i>
          </p>
        </div>
        <div class="col-6 col-lg-3" @click="goToSection('assets')">
          <h3 class="mb-0">{{ totalDishes }}</h3>
          <p>
            Total Dishes
            <i class="fas fa-angle-right ml-2"></i>
          </p>
        </div>
        <div
          class="col-6 col-lg-3"
          @click="goToSection('assets', 'Unaccounted')"
        >
          <h3 class="mb-0">{{ totalUnaccounted }}</h3>
          <p class="bg-danger text-white">
            Unaccounted Assets
            <i class="fas fa-angle-right ml-2"></i>
          </p>
        </div>
        <div class="col-6 col-lg-3" @click="scrollToIssues">
          <h3 class="mb-0">{{ issues.length }}</h3>
          <p class="bg-danger text-white">
            Issues Identified
            <i class="fas fa-angle-right ml-2"></i>
          </p>
        </div>
      </div>
      <h3 class="title border-0">
        <i class="fas fa-list mr-2"></i>
        Asset Breakdown by Carrier
      </h3>
      <h-scroller>
        <div class="telco-summary mb-4 position-relative">
          <div
            class="telco"
            v-for="telco in relevantTelcos"
            :key="telco.id"
            @click="goToSection('assets', telco.id)"
          >
            <img :src="telco.logo.url" alt="" />
            <div class="px-2">
              <span class="mr-3 font-weight-bold">
                {{ totalAntennasByTelco(telco) }}
              </span>
              <span class="font-italic text-muted">
                {{ totalAntennasByTelco(telco) > 1 ? "antennas" : "antenna" }}
              </span>
            </div>
            <div class="px-2">
              <span class="mr-3 font-weight-bold">
                {{ totalDishesByTelco(telco) }}
              </span>
              <span class="font-italic text-muted">
                {{ totalDishesByTelco(telco) > 1 ? "dishes" : "dish" }}
              </span>
            </div>
          </div>
        </div>
      </h-scroller>
      <h3 class="title border-0">
        <i class="fas fa-exclamation-triangle"></i>
        Issues Found on Site Visits
      </h3>
      <div id="tower-issues" class="row mx-0">
        <div
          class="col-12 col-lg-6"
          v-for="issue in sortedIssues"
          :key="issue.id"
        >
          <div class="issue">
            <button
              class="btn btn-link p-0 mr-3"
              @click="openGallery(issue.id)"
            >
              <img :src="issue.thumbImage.url" />
            </button>
            <div>
              <h2>{{ issue.observation }}</h2>
              <h3>Recommendation</h3>
              <p>{{ issue.recommendation }}</p>
            </div>
            <span
              class="badge badge-pill small"
              :class="issue.severity.toLowerCase()"
            >
              <i class="fas fa-exclamation-triangle mr-1"></i>
              {{ issue.severity }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import API from "@/api";
import HorizontalScroller from "@/components/HorizontalScroller";

export default {
  name: "towerDetailsOverview",
  props: ["tower"],
  components: {
    "h-scroller": HorizontalScroller,
  },
  data() {
    return {
      telcos: [],
      issues: [],
      assets: [],
    };
  },
  computed: {
    totalAntennas() {
      return this.assets.filter((asset) => asset.type == "Antenna").length;
    },
    totalDishes() {
      return this.assets.filter((asset) => asset.type == "Dish").length;
    },
    totalUnaccounted() {
      return this.assets.filter((asset) => asset.telco == null).length;
    },
    relevantTelcos() {
      return this.telcos.filter((telco) => {
        const asset = this.assets.find(
          (asset) => asset.telco && asset.telco.id == telco.id
        );
        return typeof asset != "undefined";
      });
    },
    towerData() {
      const kv = [];
      Object.keys(this.tower.tower).forEach((key) => {
        if (
          [
            "id",
            "_id",
            "createdAt",
            "updatedAt",
            "__v",
            "site",
            "ancillariesLayoutImage",
          ].includes(key)
        )
          return;
        const result = key.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        kv.push({ k: finalResult, v: this.tower.tower[key] });
      });
      return kv;
    },
    sortedIssues() {
      if (this.issues.length == 0) return [];
      const severity = ["Critical", "Medium", "Low"];
      return this.issues.sort((a, b) => {
        return severity.indexOf(a.severity) - severity.indexOf(b.severity);
      });
    },
  },
  methods: {
    openGallery(id) {
      this.$router.replace({
        query: {
          media_type: "issues",
          media_id: id,
        },
      });
    },
    goToSection(section, type) {
      if (`tower.details.${section}` !== this.$route.name) {
        this.$router.push({
          name: `tower.details.${section}`,
          params: {
            id: this.$route.params.id,
          },
          query: type ? { telco: type } : {},
        });
      }
    },
    scrollToIssues() {
      if ($("#tower-issues").length > 0) {
        $("html, body").animate({
          scrollTop: $("#tower-issues").offset().top,
        });
      }
    },
    totalAntennasByTelco(telco) {
      return this.assets.filter(
        (asset) =>
          asset.telco && asset.telco.id == telco.id && asset.type == "Antenna"
      ).length;
    },
    totalDishesByTelco(telco) {
      return this.assets.filter(
        (asset) =>
          asset.telco && asset.telco.id == telco.id && asset.type == "Dish"
      ).length;
    },
  },
  async mounted() {
    this.$parent.isLoading = true;
    this.issues = (await API.get(`issues?site=${this.tower.id}`)).data;
    this.assets = (await API.get(`assets?tower=${this.tower.tower.id}`)).data;
    this.telcos = (await API.get("telcos")).data;
    this.$parent.isLoading = false;
  },
};
</script>